<template>
  <base-dialog v-model="dialog" title="Painel de Ações" max-width="700">
    <v-container fluid>
      <v-row>
        <v-col cols="8">
          <mf-select v-model="commandAction" :disabled="!selected.length" :items="commands" label="Ação/Comando"></mf-select>
        </v-col>
        <v-col class="mt-3" cols="auto">
          <mf-button :disabled="!selected.length || !commandAction" icon="send" color="primary" label="Executar" @click="triggerAction"></mf-button>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-alert v-if="!selected.length" dense type="error" class="text-center">
            Certifique-se de selecionar pelo menos um contato antes de executar as ações
          </v-alert>

          <div v-else>
            <h4 style="text-align: center">Contatos:</h4>
            <v-row>
              <v-chip
                v-for="item in selected"
                :key="item._id"
                class="mx-auto my-2"
                style="justify-content: space-between"
                close
                @click:close="removeSelected(item._id)"
              >
                {{ formatNumber(item.number) }}
              </v-chip>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-dialog>
</template>

<script>
import { MUTATION_TRIGGER_WPP_ACTION } from '@/modules/accounts/graphql'
import BaseDialog from '../../../../components/atomic-components/atoms/BaseDialog.vue'

export default {
  components: {
    BaseDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    },
    accountId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      commands: ['desativar_contatos', 'ativar_contatos', 'excluir_contatos'],
      commandAction: null,
      dialog: this.value
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      this.$emit('input', this.dialog)
    }
  },
  methods: {
    removeSelected(id) {
      const selected = this.selected.filter(item => item._id === id)
      this.selected.splice(this.selected.indexOf(selected[0]), 1)
      this.$emit('update:selected', this.selected)
    },
    closeDialog() {
      this.$emit('input', false)
    },
    async triggerAction() {
      const numbersId = this.selected.map(item => {
        return item._id
      })
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_TRIGGER_WPP_ACTION,
          variables: {
            account_id: this.accountId,
            id_list: numbersId,
            command: this.commandAction
          }
        })
        this.$alert({
          alert_message: `Comando '${this.commandAction}' executado com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.$emit('input', false)
      } catch (err) {
        console.log(err)
        this.$alert({
          alert_message: 'Falha ao executar comando!',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    formatNumber(n) {
      const countryCode = n.slice(0, 2)
      const ddd = n.slice(2, 4)
      const phone = n.slice(4, n.length)
      return `+${countryCode} (${ddd}) ${phone}`
    }
  }
}
</script>
